@import './icons/style.css';
/* @import url('https://p.typekit.net/p.css?s=1&k=cwr0jvh&ht=tk&f=26772.26773.26774.26775&a=39520031&app=typekit&e=css'); */

/* @font-face {
	font-family: BraindMurakamiNeon;
	src: url('/fonts/BraindMurakamiNeonRegular.woff2') format('woff2'), url('/fonts/BraindMurakamiNeonRegular.woff') format('woff'), url('/fonts/BraindMurakamiNeonRegular.ttf') format('opentype');
	font-display: swap;
	font-style: normal;
	font-weight: unset;
}

@font-face {
	font-family: HackBold;
	src: url('https://use.typekit.net/af/4a5eb9/00000000000000007735b5bf/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3') format('woff2'), url('https://use.typekit.net/af/4a5eb9/00000000000000007735b5bf/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3') format('woff'), url('https://use.typekit.net/af/4a5eb9/00000000000000007735b5bf/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3') format('opentype');
	font-display: swap;
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: HackBold;
	src: url('/fonts/Hack-Bold.woff2') format('woff2'), url('/fonts/Hack-Bold.woff') format('woff'), url('/fonts/Hack-Bold.ttf') format('opentype');
	font-display: swap;
	font-style: normal;
	font-weight: unset;
	font-weight: 700;
	unicode-range: U+0410-044F;
}

@font-face {
	font-family: HackRegular;
	src: url('https://use.typekit.net/af/062cdc/00000000000000007735b5c2/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3') format('woff2'), url('https://use.typekit.net/af/062cdc/00000000000000007735b5c2/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3') format('woff'), url('https://use.typekit.net/af/062cdc/00000000000000007735b5c2/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3') format('opentype');
	font-display: swap;
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: HackRegular;
	src: url('/fonts/Hack-Regular.woff2') format('woff2'), url('/fonts/Hack-Regular.woff') format('woff'), url('/fonts/Hack-Regular.ttf') format('opentype');
	font-display: swap;
	font-style: normal;
	font-weight: unset;
	unicode-range: U+0410-044F;
} */

@font-face {
	font-family: arm-regular;
	src: url('/fonts/arm-regular.woff2') format('woff2'), url('/fonts/arm-regular.woff') format('woff'), url('/fonts/arm-regular.ttf') format('opentype');
	font-display: swap;
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: arm-bold;
	src: url('/fonts/arm-bold.woff2') format('woff2'), url('/fonts/arm-bold.woff') format('woff'), url('/fonts/arm-bold.ttf') format('opentype');
	font-display: swap;
	font-style: normal;
	font-weight: 700;
}
