@font-face {
	font-family: 'icomoon';
	src: url('fonts/icomoon.eot?aj9j6m');
	src: url('fonts/icomoon.eot?aj9j6m#iefix') format('embedded-opentype'), url('fonts/icomoon.woff2?aj9j6m') format('woff2'), url('fonts/icomoon.ttf?aj9j6m') format('truetype'), url('fonts/icomoon.woff?aj9j6m') format('woff'), url('fonts/icomoon.svg?aj9j6m#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-card:before {
	content: '\e91e';
}
.icon-cash:before {
	content: '\e918';
}
.icon-idram:before {
	content: '\e919';
}
.icon-ineco:before {
	content: '\e91a';
}
.icon-pos-terminal:before {
	content: '\e91b';
}
.icon-telcell:before {
	content: '\e91c';
}
.icon-visa:before {
	content: '\e91d';
}
.icon-linkedin:before {
	content: '\e917';
}
.icon-sliderArrLeft:before {
	content: '\e915';
}
.icon-sliderArrRight:before {
	content: '\e916';
}
.icon-Icon-material-keyboard-arrow-down:before {
	content: '\e914';
}
.icon-Icon-awesome-eye:before {
	content: '\e913';
}
.icon-photo-camera-interface-symbol-for-button:before {
	content: '\e912';
}
.icon-arrow-bottom:before {
	content: '\e900';
}
.icon-basket:before {
	content: '\e901';
}
.icon-currency:before {
	content: '\e902';
}
.icon-facebook:before {
	content: '\e903';
}
.icon-Icon-awesome-eye-slash:before {
	content: '\e904';
}
.icon-Icon-ionic-logo-twitter:before {
	content: '\e905';
}
.icon-Icon-ionic-md-arrow-back:before {
	content: '\e906';
}
.icon-Icon-ionic-md-arrow-right:before {
	content: '\e907';
}
.icon-insta:before {
	content: '\e908';
}
.icon-loginAva:before {
	content: '\e909';
}
.icon-Mask-Group-68:before {
	content: '\e90a';
}
.icon-menu:before {
	content: '\e90b';
}
.icon-minus:before {
	content: '\e90c';
}
.icon-plus:before {
	content: '\e90d';
}
.icon-samurai:before {
	content: '\e90e';
}
.icon-search:before {
	content: '\e90f';
}
.icon-search-3:before {
	content: '\e910';
}
.icon-X:before {
	content: '\e911';
}
